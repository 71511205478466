import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const isDebug = process.env.NODE_ENV !== 'production';
let plugins = [];
if (isDebug) {
    plugins.push(Vuex.createLogger());
}

export const store = new Vuex.Store({
    strict: isDebug,
    plugins: plugins,
    state: {},
    getters: {},
    mutations: {},
    actions: {}
});