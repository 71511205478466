import Vue from 'vue';
import '@/app';
import  i18n from '@/i18n';
import App from './index.vue';
import { store } from '@/store';

const selector = 'giftcertificates-plugin-frontend';

export default function (options) {
    options = options || {};

    Vue.prototype.$pluginUrl = options?.settings?.plugin_url || '';
    Vue.prototype.csrf = options?.settings?.csrf || '';

    i18n.locale = i18n.fallbackLocale = options?.settings?.locale || '';

    new Vue({
        i18n,
        store,
        render(h) {
            return h(App, {
                props: {
                    settings: options.settings || {},
                    selector: selector
                }
            });
        }
    }).$mount('#' + selector);
}
