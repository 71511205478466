<template>
    <div class="giftcertificates-plugin">
        <certificate-form :settings="settings" :selector="selector" @saved="onSaved"></certificate-form>
    </div>
</template>

<script>

export default {
    props: {
        selector: String,
        settings: Object,
    },
    components: {
        'certificate-form': () => import('@/pages/marketing_item/index.vue')
    },
    methods: {
        onSaved(response) {
            $(document).trigger('giftcertificate-created', [response]);
        }
    }
}

</script>