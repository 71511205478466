import Vue from 'vue';
import axios from 'axios';
import VModal from 'vue-js-modal';
import load from 'load.js';
import 'vue-js-modal/dist/styles.css';

Vue.use(VModal, {
    injectModalsContainer: true,
    dynamic: true,
    dynamicDefaults: {
        adaptive: true,
        scrollable: true,
        height: "auto",
        classes: 'vue-dialog',
        width: '90%'
    }
});
Vue.prototype.$http = axios;
Vue.prototype.$loadScript = load;

